.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-side{
    display: flex;
    flex: 1 1;
    text-transform: uppercase;
    gap: 2rem;
    flex-direction: column;
    color: white;
}

.left-side>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}

.left-side>:nth-child(2),
.left-side>:nth-child(3){
font-weight: bold;
font-size: 2rem;
}

.left-side>:nth-child(5)>span{
color: var(--orange);
}

.left-side>:nth-child(4)
{
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right-side{
    flex: 1;
    position: relative;
}

.right-side>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.right-side>:nth-child(1){
    position: absolute;
    width: 17rem; height: 20rem;
    border: 2px solid orange;
    background-color: transparent; 
    right: 9rem;
    top: 0.9rem;
}

.right-side>:nth-child(2){
    position: absolute;
    width: 17rem; height: 20rem;
    background: var(--planCard); 
    right: 7rem;
    top: 4rem;
}

.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 15rem;
}

.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .testimonials{
        flex-direction: column;
    }

    .left-side>:nth-child(2),.left-side>:nth-child(3){
        font-size: xx-large;
    }

    .right-side{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-side>div{
        position: relative;
        display: flex;
        top: 3rem;
        margin-top: -1.7rem;
        
    }
    .right-side>div:nth-child(1){
        width: 16rem; height: 10rem;
        right: -1.5rem;
    }
    .right-side>div:nth-child(2){
        width: 17rem; height: 19rem;
        right: -1.8rem;
        top: 3rem;
    }
    .right-side>img{
        top:-0.5rem; right: 0; position: relative;
    }

    .right-side>:last-child{
        display: block;
        bottom: 0; left: 0;
    }
}