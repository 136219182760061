.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
     position: absolute;
     width: 10.5rem;
     border: 2px solid var(--orange);
     border-radius: 20%;
     margin: -10px 0;
     /* bottom: 100px; */
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    color: var(--lightgray);
    outline: none;
}
::placeholder{
    color: var(--lightgray);
    font-size: 12.9px;
}

.btn-j{
    background-color: var(--orange);
    color: white; 
}

@media screen and (max-width:768px) 
{
    .Join{
       flex-direction: column;
       gap: 1rem;
       
    }
    .left-j{
        font-size: x-large;
        flex-direction: column; 
        padding: 2rem;
        
        margin-bottom: 4rem;
     }

     .left-j>div:nth-child(2)>:nth-child(1){
       position: absolute;margin-left: -2rem;
     }
     .left-j>div:nth-child(2)>:nth-child(2){
        position: absolute;
        margin-top: 1.5rem;
      }
      .left-j>div:nth-child(3)>:nth-child(1){
        position: absolute;
        margin-top: 3rem;margin-left: 1rem;
      }
      .left-j>div:nth-child(3)>:nth-child(2){
        position: absolute;
        margin-top: 4.5rem;margin-left: 5rem;
      }
      .left-j>hr{
        left: -0.5rem;
      }

      .email-container{
        margin-top: 1rem;
        padding: 0.5rem 0.1rem;
      }

      .btn-j{
        border: 1rem;
        position: absolute;
        margin-top: 2rem;
        margin-left: 4.5rem;
      }

}